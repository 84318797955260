/*!
 * Allows checkbox to have meaningful indeterminate state representing null in a rails nullable boolean
 * Author: Jeff Fendley
 * Date: 2014-12-19
 */
(function($) {
  $.fn.indeterminate = function (value) {
    if (typeof(value) === 'undefined') {
      return this.prop('readOnly');
    }
    this.each(function () {
      this.indeterminate = value;
      $(this).prop('readOnly', value);
      this.value = value ? '' : 1;
      $('input[type=hidden][name="' + this.name + '"]').val(value ? '' : 0);
    })
  };

  $(function(){
    // Turn select boxes with '', 1, 0 values into indeterminate checkboxes
    $('select[data-toggle=indeterminate]')
      .each(function () {
        var checkbox = $(this).after('<input type="checkbox" value="1" />').next();
        $(checkbox)
          .attr('name', this.name)
          .attr('data-indeterminate', $(this).val() == '' ? 'true' : '')
          .prop('checked', this.value == 'true')
          .before('<input type="hidden" value="0" name="' + this.name + '" />')
        ;
        $(this).remove();
      });

    // Wire up indeterminate behavior
    $('input[type=checkbox][data-indeterminate]')
      .each(function () {
        //ensure the attributes and hidden values are set correctly.
        //value is indeterminate if data-indeterminate == 'true'; otherwise checked attribute is honored directly
        $(this).indeterminate(!!$(this).data('indeterminate'));
      })
      .on('click', function () {
        // checked -> indeterminate -> unchecked ->
        if ($(this).indeterminate()) {
          $(this).indeterminate(false);
          this.checked = false;
        } else {
          if (!this.checked) {
            $(this).indeterminate(true);
            this.checked = true;
          }
        }
      });
  });
})(jQuery);
