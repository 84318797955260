import { gql } from "@apollo/client";

export const getRealTimeReadingsQuery = gql`
  query getRealTimeReadings($projectId: [Int!], $region: String, $planId: Int, $atrId: Int, $day: String, $analyte: String, $instrument: String, $units: String, $conc: String, $detects: Boolean, $detectionFlag: String, $locationId: Int, $locationCategory: String, $startingAt: String, $endingAt: String, $initials: String) {
    projects(id: $projectId) {
      latitude
      longitude
      projectFiles(type: "KML") {
        id
        zIndex
        secretHash
      }
      realTimeReadings(day: $day, region: $region, analyte: $analyte, instrument: $instrument, units: $units, conc: $conc, detects: $detects, detectionFlag: $detectionFlag, locationId: $locationId, locationCategory: $locationCategory, planId: $planId, atrId: $atrId, startingAt: $startingAt, endingAt: $endingAt, initials: $initials) {
        id
        readingDate
        latitude
        longitude
        analyte
        atrId
        concentration
        instrument
        instrumentBarcode
        detectionLimit
        detectionFlag
        units
        region
        locationCategory
        weatherWindString
        weatherWindDir
        weatherWindDegrees
        weatherWindMph
        weatherWindGustMph
        weatherWeather
        weatherTempF
      }
    }
  }`;