import React, { useState } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";

const TransformationForm = ({ fieldName, transformation, recordType, timeZoneAbbrev }) => {
  const [newTransformation, setNewTransformation] = useState(() => {
    return isEmpty(transformation) ? [] : Object.entries(JSON.parse(transformation))
  });
  // handle the user deleting the last entry
  if (isEmpty(newTransformation)) {
    setNewTransformation([["", ""]]);
  }
  const newTransformationJSON = Object.fromEntries(newTransformation);
  const addEmptyField = (i) => {
    setNewTransformation([
      ...newTransformation.slice(0, i + 1),
      ["", ""],
      ...newTransformation.slice(i + 1)
    ]);
  };
  const removeField = (i) => {
    setNewTransformation([
      ...newTransformation.slice(0, i),
      ...newTransformation.slice(i + 1)
    ]);
  };
  const updateField = (i, fieldName) => {
    const updated = [...newTransformation];
    updated[i][0] = fieldName;
    setNewTransformation(updated);
  };
  const updateTransform = (i, transform) => {
    const updated = [...newTransformation];
    updated[i][1] = transform;
    setNewTransformation(updated);
  };
  const fieldUpdater = (i) => ((evt) => updateField(i, evt.target.value));
  const transformUpdater = (i) => ((evt) => updateTransform(i, evt.target.value));

  return (
    <>
      <input type="hidden" name={fieldName} value={JSON.stringify(newTransformationJSON)} />
      <table className="table">
        <thead>
          <tr>
            <th></th>
            <th>Field</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {/* {newTransformation.map(([k, v], i) => ( */}
          {(isEmpty(newTransformation) ? [["", ""]] : newTransformation).map(([k, v], i) => (
            <tr key={i}>
              <td style={{ whiteSpace: "nowrap" }}>
                <button type="button" className="btn btn-sm text-success" style={{ marginRight: "0.5em" }} onClick={() => addEmptyField(i)}>+</button>
                <button type="button" className="btn btn-sm text-danger" onClick={() => removeField(i)}>X</button>
              </td>
              <td><FieldSelector fields={recordType.fields} value={k} onChange={fieldUpdater(i)} /></td>
              <td><TransformEditor field={recordType.fields[k]} value={v} onChange={transformUpdater(i)} {...{ timeZoneAbbrev }} /></td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

const FieldSelector = ({ fields, value, onChange }) => {
  return (
    <select value={value} onChange={onChange} className="form-control">
      <option></option>
      {Object.entries(fields).map(([k, v]) => (
        <option key={k}>{k}</option>
      ))}
    </select>
  );
};

const TransformEditor = ({ field, value, onChange, timeZoneAbbrev }) => {
  return field?.type == "boolean" ? (
    <select className="form-control" value={value} onChange={onChange}>
      <option value="true">true</option>
      <option value="false">false</option>
      {field.null && <option value="{{''}}">not set</option>}
      <option value={`{{r.${field.name} | toggle}}`} >toggle value</option>
      {field.null && <option value={`{{r.${field.name} | to_boolean}}`} >false if not set</option>}
    </select >
  ) : field?.type == "date" ? (
    <input type="date" className="form-control" value={value} onChange={onChange} />
  ) : field?.type == "datetime" ? (
    <DateTimeTransformEditor {...{ field, value, onChange, timeZoneAbbrev }} />
  ) : field?.options ? (
    <SelectEditor {...{ field, value, onChange }} />
  ) : (
    <input type="text" className="form-control" value={value} onChange={onChange} />
  );
};

const SelectEditor = ({ field, value, onChange }) => {
  const [isFormula, setIsFormula] = useState(() => !isEmpty(field.options) && !field.options.some((v) => value === v[1]));
  return (
    <div className="input-group">
      {isFormula ? (
        <input type="text" className="form-control" value={value} onChange={onChange} />
      ) : (
        <select className="form-control" value={value} onChange={onChange}>
          {field.null && <option value=""></option>}
          {field.options.map(([display, value]) => (
            <option key={value} value={value}>{display}</option>
          ))}
        </select>
      )}
      <div className="input-group-btn">
        <button type="button" className="btn" onClick={() => setIsFormula(!isFormula)}>
          {isFormula ? "sel" : <strong>sel</strong>}
          |
          {!isFormula ? "fm" : <strong>fm</strong>}
        </button>
      </div>
    </div>
  );
};

const DateTimeTransformEditor = ({ field, value, onChange, timeZoneAbbrev }) => {
  const [isFormula, setIsFormula] = useState(() => !/^[\d-]{10}T[\d:]+$/.test(value));
  return (
    <div className="input-group">
      {isFormula ? (
        <input type="text" className="form-control" value={value} onChange={onChange} />
      ) : (
        <input type="datetime-local" className="form-control" value={value} onChange={onChange} />
      )}
      <div className="input-group-addon">
        {timeZoneAbbrev}
      </div>
      <div className="input-group-btn">
        <button type="button" className="btn" onClick={() => setIsFormula(!isFormula)}>
          {isFormula ? "dt" : <strong>dt</strong>}
          |
          {!isFormula ? "fm" : <strong>fm</strong>}
        </button>
      </div>
    </div>
  );
};

TransformationForm.propTypes = {
  fieldName: PropTypes.string,
  transformation: PropTypes.string,
  recordType: PropTypes.object,
};

export default TransformationForm;
