/*!
 * Adds auto-wire-up of bootstrap-datetime-picker
 * Author: Jeff Fendley
 * Date: 2014-02-21
 */
(function($){
  $(function(){
    $('input[data-type="date"]')
      .each(function(){
        $(this).data('format', $(this).data('format') || 'YYYY-MM-DD')
        .datetimepicker({format: $(this).data('format')})
      })
    ;
    $('input[data-type="time"]')
      .each(function(){
        $(this).data('format', $(this).data('format') || 'HH:mm:ss')
        .datetimepicker({format: $(this).data('format')})
      })
    ;
    $('input[data-type="datetime"]')
      .each(function(){
        $(this).data('format', $(this).data('format') || 'YYYY-MM-DD HH:mm:ss')
        .datetimepicker({format: $(this).data('format')})
      })
    ;
    $('input[data-type="cal_datetime"]')
        .each(function(){
          $(this).data('format', $(this).data('format') || 'YYYY-MM-DD HH:mm')
              .datetimepicker({
                format: $(this).data('format'),
                stepping: 5,
                keepInvalid: true,
              })
        })
    ;
  })
})(jQuery)