import React from "react";
import {Marker as LeafletMarker} from "leaflet";
import {withLeaflet, MapLayer, LeafletProvider} from "react-leaflet";

class StatefulMarker extends MapLayer {
  componentDidMount() {
    super.componentDidMount();
    this.handleActive();
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps);
    this.handleActive();
  }

  handleActive() {
    if (this.props.active === true) {
      this.leafletElement.openPopup();
      this.leafletElement._map.setView(this.leafletElement.options.position, 15);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.active !== this.props.active;
  }

  createLeafletElement(props) {
    const el = new LeafletMarker(props.position, this.getOptions(props));
    this.contextValue = {...props.leaflet, popupContainer: el};
    return el;
  }

  updateLeafletElement(fromProps, toProps) {
    if (toProps.position !== fromProps.position) {
      this.leafletElement.setLatLng(toProps.position);
    }

    if (toProps.icon !== fromProps.icon) {
      this.leafletElement.setIcon(toProps.icon);
    }

    if (toProps.zIndexOffset !== fromProps.zIndexOffset) {
      this.leafletElement.setZIndexOffset(toProps.zIndexOffset);
    }
  }

  render() {
    const {children} = this.props;
    return children == null || this.contextValue == null ? null : (
      <LeafletProvider value={this.contextValue}>{children}</LeafletProvider>
    );
  }
}

export default withLeaflet(StatefulMarker);
