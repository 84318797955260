const componentRequireContext = require.context("components", true);
const ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

require.context('../images', true);

import "../src/js/vendor";
import "../src/scss/application.scss";

import "../src/js/init";
import "../src/js/jquery-rails-indeterminate-checkbox";
import "../src/js/bootstrap-datetime-picker-auto";

import "../src/js/sorttable";
import "../src/js/rails-ajax-toggle";
import "../src/js/zebra_tables";
import "../src/js/samples_map";
import "../src/js/typeahead_twbs3";

import * as Sentry from '@sentry/browser';
if (NODE_ENV !== 'development') {
  Sentry.init({ dsn: 'https://214b4024f4444aed825aa293e5562d6a@sentry.io/1408450' });
}