import React from "react";
import {MapControl, withLeaflet} from "react-leaflet";
import omnivore from "@mapbox/leaflet-omnivore";

class LeafletKml extends MapControl {
  createLeafletElement(props) {
    return omnivore.kml(props.kml);
  }
}

export default withLeaflet(LeafletKml);