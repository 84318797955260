/*!
 * Adds auto-wire-up of twitter/typeahead from data attributes, and bootstrap3 support
 * Author: Jeff Fendley
 * Date: 2014-01-21
 */
import jQuery from "jquery";

(function ($) {
  var TypeaheadHelper = function (element, options) {
    this.options = options;
    this.$element = $(element);
    this.typeaheadTemplate = options.typeaheadOptions.template || function (datum) {
      return datum.value;
    };

    if (this.options.multi) {
      this.initializeMulti();
    }
    this.initializeTypeahead();
    this.fixBootstrapStyle();
  };

  TypeaheadHelper.prototype.initializeTypeahead = function () {
    this.$element.typeahead(this.options.typeaheadOptions);
    var jumpTo = this.options.jumpTo;
    if (jumpTo) {
      this.$element.on("typeahead:autocompleted typeahead:selected", function (evt, suggestion, dataset) {
        window.location.href = jumpTo(suggestion);
      });
    }
  };

  TypeaheadHelper.prototype.initializeMulti = function () {
    var self = this;
    var originalName = self.$element.attr("name");
    var updatedName = originalName + "_typeahead";
    self.$element.attr("name", updatedName);

    self.$hiddenField = $("<input type=\"hidden\" name=\"" + originalName + "\" />");
    self.$hiddenField.val(self.$element.val());
    self.$element.val("");
    self.$displayList = $("<ul class=\"list-unstyled " + originalName + "_selected\"></ul>");
    self.$element.after(self.$hiddenField);
    self.$element.after(self.$displayList);
    self.selected = self.options.selected || [];
    self.selectedDisplayTemplateEngine = _.template("<li data-value=\"${value}\">${text} <i data-action=\"delete\" class=\"fa fa-minus-circle\"></i></li>");

    if (self.selected) {
      self.updateSelectedDisplay();
      self.updateSelectedValue();
    }

    self.$displayList.on("click", "li>[data-action=\"delete\"]", function () {
      self.removeFromSelected($(this).closest("li").data("value"));
    });

    self.$element.on("typeahead:selected typeahead:autocompleted", function (el, datum, dataset) {
      self.addToSelected(datum);
      self.$element.val("");
      self.$element.focus();
    });

    self.$element.on("typeahead:closed", function () {
      self.$element.val("");
    });
    self.$element.on("focus,blur", function () {
      self.$element.val("");
    });
  };

  TypeaheadHelper.prototype.updateSelectedDisplay = function () {
    var self = this;
    self.$displayList.empty();
    if (self.selected.length <= 0) {
      self.$displayList.append("<li style=\"font-style:italic;\">No items selected.</li>");
    } else {
      $.each(self.selected, function (i, datum) {
        if (!$.isPlainObject(datum)) {
          datum = {value: datum};
        }
        var displayData = {
          value: datum.value,
          text: self.typeaheadTemplate(datum)
        };
        self.$displayList.append(self.selectedDisplayTemplateEngine(displayData));
      });
    }
  };

  TypeaheadHelper.prototype.updateSelectedValue = function () {
    var self = this;
    var val = _.map(self.selected, "value").join(",");
    self.$hiddenField.val(val);
  };

  TypeaheadHelper.prototype.addToSelected = function (datum) {
    var self = this;
    if (!_.some(self.selected, {"value": datum.value})) {
      self.selected.push(datum);
      self.$element.data("selected", self.selected);
      self.updateSelectedDisplay();
      self.updateSelectedValue();
    }
  };

  TypeaheadHelper.prototype.removeFromSelected = function (datumValue) {
    var self = this;
    self.selected = $.grep(self.selected, function (datum, i) {
      return datum.value != datumValue;
    });
    self.$element.data("selected", self.selected);
    self.updateSelectedDisplay();
    self.updateSelectedValue();
  };

  TypeaheadHelper.prototype.getDatumFromValue = function (value) {
    var self = this;
    return value;
  };

  TypeaheadHelper.prototype.fixBootstrapStyle = function () {
    $(".twitter-typeahead .input-sm").siblings("input.tt-hint").addClass("hint-small");
    $(".twitter-typeahead .input-lg").siblings("input.tt-hint").addClass("hint-large");
    $(".twitter-typeahead [data-complete], .twitter-typeahead [data-complete-stand-in]").each(function () {
      $(this).siblings("input.tt-hint").css({"max-width": $(this).width()});
    });
  };

  $(function () {
    $("[data-complete=\"typeahead\"][data-complete-options]").each(function () {
      var $this = $(this);

      var completeOptions = $this.data("completeOptions");
      _.remove(completeOptions || [], function (v) {
        return _.isNull(v);
      });
      var typeaheadOptions = {local: completeOptions};
      if ($this.data("completeTemplate")) {
        if (typeof (_) !== "undefined") {
          typeaheadOptions.template = _.template($this.data("completeTemplate"));
        }
      }
      if ($this.data("completeAutoselect")) {
        typeaheadOptions.autoselect = $this.data("completeAutoselect");
      }

      var jumpTo;
      if ($this.data("completeJumpTo")) {
        jumpTo = _.template($this.data("completeJumpTo"));
      }

      var typeaheadHelper = new TypeaheadHelper(this, {
        multi: $this.is("[data-multi]"),
        selected: $this.data("selected"),
        jumpTo: jumpTo,
        typeaheadOptions: typeaheadOptions
      });
    });
  });
})(jQuery);