import React from "react";
import moment from "moment";
import {LayersControl, Map, Marker, Popup, TileLayer} from "react-leaflet";
import LeafletKml from "./Leaflet/LeafletKml";
import StatefulMarker from "./Leaflet/StatefulMarker";

class ProjectsMap extends React.Component {
  static concentrationOrDl(rtr) {
    if (rtr.concentration !== null && rtr.concentration !== "") {
      return rtr.concentration;
    }

    return `${rtr.detectionFlag}${rtr.detectionLimit}`;
  }

  static validLatLng(record) {
    return record.latitude !== null && record.latitude !== undefined && record.latitude !== 0 && record.latitude !== "" &&
      record.longitude !== null && record.longitude !== undefined && record.longitude !== 0 && record.longitude !== "";
  }

  sieraMarkers() {
    if (!this.props.project.sieras || this.props.project.sieras.length === 0) {
      return (<></>);
    }

    return this.props.project.sieras
      .filter(s => ProjectsMap.validLatLng(s) && s.sieraSubtype !== "Chain Of Custody")
      .map(s => {
        return <Marker position={[s.latitude, s.longitude]}>
          <Popup>
            {ProjectsMap.sieraPopup(s)}
          </Popup>
        </Marker>;
      });
  }

  static sieraPopup(siera) {
    let title = ``;

    if (siera.dateTime !== null && siera.dateTime !== undefined && siera.dateTime !== "") {
      title += ` - <span style="white-space:nowrap">${moment(siera.dateTime).format("YYYY/MM/DD H:mm")}</span>`;
    }

    return (
      <div>
        <a href={`/sieras/${siera.id}`}>{siera.sieraType} {siera.sieraSubtype} {siera.primaryIdentifier}</a>
        <div>{siera.locationDescription}</div>
        <div>{siera.sieraType} - {siera.sieraSubtype}</div>
        <div>{siera.primaryIdentifier} {siera.secondaryIdentifier}</div>
        <div>{siera.comments}</div>
      </div>
    );
  }

  static rtrPopup(rtr) {
    return (
      <div>
        <a href={`/real_time_readings/${rtr.id}`}>{moment.utc(rtr.readingDate).format("YYYY/MM/DD H:mm")}</a>
        <div>{rtr.initials}</div>
        <div>{rtr.instrument} - {rtr.instrumentBarcode}</div>
        <div>{rtr.analyte} - {this.concentrationOrDl(rtr)} {rtr.units}</div>
      </div>
    );
  }

  projectKmls() {
    if (!this.props.project.projectKmls || this.props.project.projectKmls.length === 0) {
      return (<></>);
    }
    
    return this.props.projects.projectKmls.map(kml => {
      return <LeafletKml kml={`https://projects.cteh.com/project_files/${kml.secretHash}/link}`} />
      });
  }

  realTimeReadingMarkers() {
    if (!this.props.project.realTimeReadings || this.props.project.realTimeReadings.length === 0) {
      return (<></>);
    }

    const getRealTimeIcon = ({active, detectionFlag}) => {
      let baseClass = active ? "fa-real-time-active" : "fa-real-time";
      let finalClass = detectionFlag === "<" ? baseClass : `${baseClass} fa-real-time-detect`;

      return L.divIcon({
        html: `<i class="${finalClass}" />`,
        iconSize: [4, 4]
      });
    };

    return this.props.project.realTimeReadings
      .filter(rtr => ProjectsMap.validLatLng(rtr))
      .map(rtr => {
        if (this.props.activeRealTimeRecord !== null && this.props.activeRealTimeRecord === rtr.id) {
          return <StatefulMarker active={true} key={`rtr-${rtr.id}`} position={[rtr.latitude, rtr.longitude]} icon={getRealTimeIcon({active: true, detectionFlag: rtr.detectionFlag})}>
            <Popup>
              {ProjectsMap.rtrPopup(rtr)}
            </Popup>
          </StatefulMarker>;
        }

        return <StatefulMarker key={`rtr-${rtr.id}`} position={[rtr.latitude, rtr.longitude]} icon={getRealTimeIcon({active: false, detectionFlag: rtr.detectionFlag})}>
          <Popup>
            {ProjectsMap.rtrPopup(rtr)}
          </Popup>
        </StatefulMarker>;
      });
  }

  render() {
    if (!this.props.project) {
      return (<></>);
    }

    const position = [this.props.project.latitude, this.props.project.longitude];
    return (
      <Map center={position} zoom={13}>
        <LayersControl position="topright">
          <LayersControl.BaseLayer checked name="Satellite Streets">
            <TileLayer
                attribution="CTEH"
                url="https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v9/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiamJhcmtldCIsImEiOiJjanVpd2xtZzcwMW1jNGFxdnF3bjVnZTRnIn0.RqeDY5p8L-srwfq0k7l-4g"
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Street">
            <TileLayer
                attribution="CTEH"
                url="https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiamJhcmtldCIsImEiOiJjanVpd2xtZzcwMW1jNGFxdnF3bjVnZTRnIn0.RqeDY5p8L-srwfq0k7l-4g"
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Satellite">
            <TileLayer
                attribution="CTEH"
                url="https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiamJhcmtldCIsImEiOiJjanVpd2xtZzcwMW1jNGFxdnF3bjVnZTRnIn0.RqeDY5p8L-srwfq0k7l-4g"
            />
          </LayersControl.BaseLayer>
        </LayersControl>
        {this.projectKmls()}
        {this.realTimeReadingMarkers()}
        {this.sieraMarkers()}
      </Map>
    );
  }
}

export default ProjectsMap;
