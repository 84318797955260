function zebra(table) {
  var current = "oddRow";
  var trs = table.getElementsByTagName("tr");
  var count = 0;

  for (var i = 1; i < trs.length; i++) {
    count = count + 1;
    trs[i].className = current;
    if (count >= 5) {
      current = current == "evenRow" ? "oddRow" : "evenRow";
      count = 0;
    }
  }
};

window.zebra = zebra;


