import "moment/moment";

import $ from "jquery";
import "jquery-ujs";
import "bootstrap/dist/js/bootstrap";

import "handlebars";
import "selectize";
import "@fancyapps/fancybox";
import "easymde";
import "marked";

// css imports don't belong here, but fancybox insists on using shit
// that node-sass can't parse.
import "@fancyapps/fancybox/dist/jquery.fancybox.min.css";

import "./typeahead.min";
import "eonasdan-bootstrap-datetimepicker";

import "lodash";
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
$(function(){
  $('#calendar').each(function(){
    var calendar = new Calendar(this, {
      plugins: [ dayGridPlugin ],
      events: window.calendarEvents,
    });

    calendar.render();
  })
})

window.Highcharts = require('highcharts/highstock');
window.$ = $;
window.jQuery = $;
window.JQuery = $;
window.jquery = $;