import React, { useState } from "react";
import PropTypes from "prop-types";
import RealTimeSummaryTable from "./RealTimeSummaryTable";
import AnalyteChart from "./AnalyteChart";

const RealTimeSummary = (props) => {
  const [filter, updateFilter] = useState({
    day: props.day,
    analyte: props.analyte,
    units: props.units,
    conc: props.conc,
    initials: props.initials,
    startingAt: props.startingAt,
    endingAt: props.endingAt,
  });

  if (!props.summaryRecords) {
    return (<></>);
  }

  return (
    <>
      <div className="row">
        <div className="col-md-4">
          <RealTimeSummaryTable summaryKey={props.summaryKey} summaryRecords={props.summaryRecords} project={props.project} splitByInstrument={props.splitByInstrument} filter={filter} updateFilter={updateFilter} />
        </div>
        <div className="col-md-8">
          <AnalyteChart projectId={props.project.id} filter={filter} />
        </div>
      </div>
    </>
  );
}

RealTimeSummary.propTypes = {
  summaryRecords: PropTypes.array,
  summaryKey: PropTypes.string,
  project: PropTypes.object,
  splitByInstrument: PropTypes.bool
};

export default RealTimeSummary;
