import React from "react";
import PropTypes from "prop-types";
// import ReactTable from "react-table";
import { useTable } from "react-table";
import { isEqual, omit, omitBy, snakeCase } from "lodash";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
  )

  return (
    <>
      <table className="table table-condensed table-striped-5" {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(
            (row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    )
                  })}
                </tr>
              )
            }
          )}
        </tbody>
      </table>
    </>
  );
};

const RealTimeSummaryTable = (props) => {
  const filterMatches = (filter) => isEqual(filter, props.filter)

  const analyteFormatter = (reading) => {
    return `${reading.analyte} (${reading.units})`;
  };

  const hasFilterValues = (keys = []) => {
    return Object.entries(props.filter).some(([k, v]) => keys.includes(k) && v != null)
  };

  const buildParams = (category, limited = [], extraParams = {}) => {
    let params = Object.assign(
      {},
      props.filter,
      category == null ? {} : { [props.summaryKey]: category.categoryId },
      extraParams
    )
    params = omit(params, limited ?? [])
    params = omitBy(params, (v) => v == null)
    const paramStrings = Object.entries(params).map(([k, v]) => `${snakeCase(k)}=${encodeURIComponent(v === true ? "1" : v)}`);

    return `?${paramStrings.join("&")}`;
  };

  const render = () => {
    if (!props.summaryRecords) {
      return (<></>);
    }

    let hasDateFilter = hasFilterValues(['day', 'startingAt', 'endingAt'])

    return (
      <>
        {props.summaryRecords.map(category => {
          return (
            <React.Fragment key={category.categoryId}>
              <h3>
                {category.categoryName || <em>{`{Blank}`}</em>}
              </h3>
              <div className="links">
                <ul className="list-inline">
                  <li>
                    <a
                      href={`/projects/${props.project.id}/real_time_readings/fullscreen_map${buildParams(category)}`}>Map</a>
                  </li>
                  <li>
                    <a
                      href={`/projects/${props.project.id}/real_time_readings${buildParams(category)}`}>{hasDateFilter ? 'View Date Filtered' : 'View All'}</a>
                  </li>
                  {hasDateFilter && (
                    <li>
                      <a
                        href={`/projects/${props.project.id}/real_time_readings${buildParams(category, ['day', 'startingAt', 'endingAt'])}`}>View All Dates</a>
                    </li>
                  )}
                </ul>
              </div>
              <Table
                data={category.records}
                columns={[
                  {
                    Header: "Analyte",
                    id: "analyte",
                    accessor: r => analyteFormatter(r),
                    minWidth: 80
                  },
                  props.splitByInstrument && {
                    Header: "Instrument",
                    id: "instrument",
                    accessor: r => r.instrument,
                    minWidth: 80
                  },
                  {
                    Header: () => <>Count of<br />Readings</>,
                    id: "count_of_readings",
                    accessor: r => countAllFormatter(r),
                    minWidth: 50
                  },
                  {
                    Header: () => <>Count of<br />Detects</>,
                    id: "count_of_detects",
                    accessor: r => detectsFormatter(r),
                    minWidth: 50
                  },
                  // {
                  //   Header: () => <>Average of<br/>Detects</>,
                  //   id: "average_of_detects",
                  //   accessor: r => averageFormatter(r),
                  //   minWidth: 50
                  // },
                  // {
                  //   Header: () => <>Lowest<br/>Conc.</>,
                  //   id: "min_conc",
                  //   accessor: r => minFormatter(r),
                  //   minWidth: 60
                  // },
                  // {
                  //   Header: () => <>Highest<br/>Conc.</>,
                  //   id: "max_conc",
                  //   accessor: r => maxFormatter(r),
                  //   minWidth: 60
                  // },
                  {
                    Header: () => <>Conc. Range</>,
                    id: "conc_range",
                    accessor: r => rangeFormatter(r),
                    minWidth: 60
                  },
                ].filter(r => r)}
                defaultPageSize={20}
                showPaginationTop={false}
                showPaginationBottom={true}
                minRows={0}
                showPageSizeOptions={false}
                showPageJump={false}
                showPagination={category.records.length > 20}
                className="-striped -highlight primary-pagination real-time-summary-table" />
            </React.Fragment>
          );
        })}
      </>
    );
  }

  const formatFloat = function (floatString) {
    return parseFloat(floatString).toFixed(3).replace(/\.?0+$/, "");
  };

  const instrumentFormatter = (reading) => {
    return `${reading.instrument}`;
  };

  const standardFilter = (reading) => {
    let filter = {
      analyte: reading.analyte,
      instrument: reading.instrument,
      units: reading.units,
      day: props.filter.day,
      startingAt: props.filter.startingAt,
      endingAt: props.filter.endingAt,
    };
    filter[props.summaryKey] = reading[props.summaryKey];
    return filter;
  }

  const selectedClass = "selected label label-primary"

  const countAllFormatter = (reading) => {
    let filter = standardFilter(reading);
    let isFilterMatch = filterMatches(filter);
    let url = `/projects/${props.project.id}/real_time_readings${buildParams(null, null, filter)}`;

    return (
      isFilterMatch ? (
        <span className={selectedClass}>
          {reading.countOfReadings} <a href={url} style={{ color: 'white' }}>view</a>
        </span>
      ) : (
        <a onClick={() => props.updateFilter(filter)} className="label label-default">
          {reading.countOfReadings.toLocaleString()}
        </a>
      )
    );
  };

  const detectsFormatter = (reading) => {
    if (reading.countOfDetects === 0) {
      return (
        <span className="label" style={{ color: 'inherit' }}>
          0
        </span>
      );
    }

    let filter = standardFilter(reading);
    filter['detects'] = true

    let isFilterMatch = filterMatches(filter);
    let url = `/projects/${props.project.id}/real_time_readings${buildParams(null, null, filter)}`;

    return (
      isFilterMatch ? (
        <span className={selectedClass}>
          {reading.countOfDetects.toLocaleString()} <a href={url} style={{ color: 'white' }}>view</a>
        </span>
      ) : (
        <a onClick={() => props.updateFilter(filter)} className="label label-default">
          {reading.countOfDetects}
        </a>
      )
    );
  };

  const rangeFormatter = (reading) => {
    let filter = standardFilter(reading);
    if (reading.countOfDetects) {
      filter["detects"] = true;
    }

    return (
      <>
        <a onClick={() => props.updateFilter(filter)} className="label label-default">
          {
            reading.countOfDetects
              ? <>
                {
                  reading.minConc !== reading.maxConc && <>
                    {formatFloat(reading.minConc)}
                    &nbsp;-&nbsp;
                  </>
                }
                {formatFloat(reading.maxConc)}
                &nbsp;
                {reading.units}
              </>
              : <>
                &lt; {formatFloat(reading.maxDetectionLimit)} {reading.units}
              </>
          }
        </a>
        &nbsp;
        {aboveUpperLimitNotice(reading)}
      </>
    );
  };

  const aboveUpperLimitNotice = (reading) => {
    if (reading.countOfAboveUpperLimit === 0) {
      return;
    }

    let filter = standardFilter(reading);
    filter["detectionFlag"] = ">";

    return (
      <a onClick={() => props.updateFilter(filter)}>
        <span
          className="label label-danger"
          title={`${reading.countOfAboveUpperLimit} ${reading.countOfAboveUpperLimit === 1 ? "reading is" : "readings are"} above the upper detection limit`}
        >
          {reading.countOfAboveUpperLimit} &gt;
        </span>
      </a>
    );
  };

  return render();
}

RealTimeSummaryTable.propTypes = {
  summaryRecords: PropTypes.array,
  summaryKey: PropTypes.string,
  project: PropTypes.object,
  splitByInstrument: PropTypes.bool,
  filter: PropTypes.object
};

export default RealTimeSummaryTable;
