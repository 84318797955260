import Handlebars from "handlebars";
import { OverlappingMarkerSpiderfier } from "./oms.min";
import _ from "lodash";
import greenIcon from "../img/google_maps_green.png";
import blueIcon from "../img/google_maps_blue.png";
import redIcon from "../img/google_maps_red.png";
import workerIcon2 from "../../images/worker_icon2.png";
import spotIcon from "../../images/spot.png";

/**
 * Created by jfendley on 2/11/14.
 */
(function ($) {
  var SamplesMap = function (element, options) {
    if (typeof (google) !== "undefined" && google.maps) {
      this.options = options || SamplesMap.DEFAULTS;
      this.$element = $(element);
      this.templateCompiler = this.options.templateCompiler;

      this._keyPrefix = -1;

      this.initializeMap();
    } else {
      var console = window.console || { log: $.noop };
      console.log("Google maps not initialized");
    }
  };

  if (typeof (google) !== "undefined" && google.maps) {
    SamplesMap.green_icon = {
      url: greenIcon
      , size: new google.maps.Size(12, 20)
    };
    SamplesMap.blue_icon = {
      url: blueIcon
      , size: new google.maps.Size(12, 20)
    };
    SamplesMap.red_icon = {
      url: redIcon
      , size: new google.maps.Size(12, 20)
    };
    SamplesMap.cyan_icon = {
      path: google.maps.SymbolPath.CIRCLE,
      fillColor: "black",
      fillOpacity: 1,
      scale: 4.3,
      strokeColor: "#00FFFF"
    };

    SamplesMap.green_dot = {
      path: google.maps.SymbolPath.CIRCLE,
      fillColor: "black",
      fillOpacity: 1,
      scale: 2.6,
      strokeColor: "#46FF46"
    };
    SamplesMap.blue_dot = {
      path: google.maps.SymbolPath.CIRCLE,
      fillColor: "black",
      fillOpacity: 1,
      scale: 2.6,
      strokeColor: "#00BFFF"
    };
    SamplesMap.red_dot = {
      path: google.maps.SymbolPath.CIRCLE,
      fillColor: "black",
      fillOpacity: 1,
      scale: 2.6,
      strokeColor: "red"
    };
    SamplesMap.orange_dot = {
      path: google.maps.SymbolPath.CIRCLE,
      fillColor: "black",
      fillOpacity: 1,
      scale: 2.6,
      strokeColor: "orange"
    };
    SamplesMap.purple_dot = {
      path: google.maps.SymbolPath.CIRCLE,
      fillColor: "black",
      fillOpacity: 1,
      scale: 2.6,
      strokeColor: "purple"
    };
    SamplesMap.cyan_dot = {
      path: google.maps.SymbolPath.CIRCLE,
      fillColor: "black",
      fillOpacity: 1,
      scale: 2.6,
      strokeColor: "#00FFFF"
    };
    SamplesMap.person_icon = {
      url: workerIcon2
      , size: new google.maps.Size(24, 25)
    };
    SamplesMap.spot_icon = {
      url: spotIcon
      , size: new google.maps.Size(40, 27)
    };

    SamplesMap.basicMarkerSpec = {
      icon: SamplesMap.green_dot
      , title: "{{title}}"
      , infoWindow: {
        title: "{{title}}"
        , content: [""
          , "<div><a href=\"{{url}}\">{{title}}</a></div>"
        ].join("")
      }
    };

    SamplesMap.realTimeReadingMarkerSpec = {
      icon: SamplesMap.green_dot
      , title: "{{analyte}} {{{conc_dl}}} {{units}}"
      , infoWindow: {
        title: "{{analyte}} {{{conc_dl}}} {{units}}"
        , content: [""
          , "<div><a href=\"/real_time_readings/{{id}}\">{{id}}</a>{{#if reading_date_fmt}} - <span style=\"white-space:no-wrap\">{{reading_date_fmt}}</span>{{/if}}</div>"
          , "<div>{{initials}}</div>"
          , "{{#if location}}"
          , "<div>{{location.location_code}}</div>"
          , "{{/if}}"
          , "<div>{{analyte}}</div>"
          , "<div>{{conc_dl}} {{units}}</div>"
          , "{{#if photo_exists}}"
          , "<div><a href=\"javascript: SamplesMap.showRtrLightbox({{id}})\"><img height=\"150\" src=\"/real_time_readings/{{id}}/download_photo?style=thumb\" /></a></div>"
          , "{{/if}}"
        ].join("")
      }
    };

    SamplesMap.peopleMarkerSpec = {
      icon: SamplesMap.person_icon
      , title: "Last Location for {{initials}}"
      , infoWindow: {
        title: "Last Location for {{initials}}"
        , content: [""
          , "<div><a href=\"/real_time_readings/{{id}}\">{{id}}</a> - Last Location for {{initials}}</div>"
          , "<div style=\"font-weight: bold; white-space:no-wrap\">{{reading_date_fmt}}</div>"
        ].join("")
      }
    };

    SamplesMap.sieraMarkerSpec = {
      icon: SamplesMap.blue_dot
      , title: "{{siera_type}} {{siera_subtype}} {{primary_identifier}}"
      , infoWindow: {
        title: "{{siera_type}} {{siera_subtype}} {{primary_identifier}}"
        , content: [""
          , "<div style=\"width: 300px\">"
          , "<div><a href=\"/sieras/{{id}}\">{{title}}</a> {{#if date_time_fmt}} - <span style=\"white-space:no-wrap\">{{date_time_fmt}}{{/if}}</span></div>"
          , "<div>{{location_description}}</div>"
          , "<div>{{siera_type}} - {{siera_subtype}}</div>"
          , "<div>{{primary_identifier}} {{secondary_identifier}}</div>"
          , "{{#if photo_exists}}"
          , "<div><a href=\"javascript: SamplesMap.showSieraLightbox({{id}})\"><img height=\"150\" src=\"/sieras/{{id}}/download_photo?style=thumb\" /></a></div>"
          , "{{/if}}"
          , "<div>{{comments}}</div>"
          , "</div>"
        ].join("")
      }
    };

    SamplesMap.projectCenterMarkerSpec = {
      icon: SamplesMap.red_dot
      , title: "Project Center"
      , infoWindow: {
        title: "Project Center"
        , content: "<div>Project Center: <a href=\"{{url}}\">{{to_s}}</a></div>"
      }
    };

    SamplesMap.deviceMarkerSpec = {
      icon: SamplesMap.spot_icon
      , title: "BSCT"
      , infoWindow: {
        title: "BSCT"
        , content: "<div><h1>WOOF</h1></div>"
      }
    };

    SamplesMap.DEFAULTS = {
      mapOptions: {
        center: new google.maps.LatLng(34.8011599478, -92.673375445), //i430 bridge because it's central, recognizable
        tilt: 0,
        zoom: 6,
        zoomControl: true,
        zoomControlOptions: {
          style: google.maps.ZoomControlStyle.SMALL
        },
        mapTypeControl: true,
        scaleControl: true,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.DROPDOWN_MENU
        },
        panControl: false,
        streetViewControl: false,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      }
      , templateCompiler: Handlebars.compile
      , useWeatherLayer: false
      , useCloudLayer: false
    };
  }
  ;

  SamplesMap.prototype.initializeMap = function () {
    var self = this;
    self.map = new google.maps.Map(self.$element.get(0), self.options.mapOptions);
    self.oms = new OverlappingMarkerSpiderfier(self.map);
    self.mapHasIdled = false;
    google.maps.event.addListenerOnce(self.map, "idle", function () { self.mapHasIdled = true; });
    google.maps.event.addListener(self.map, "click", function () {
      if (self.infoWindow) {
        self.infoWindow.close();
      }
    });

    if (self.options.useWeatherLayer) {
      new google.maps.weather.WeatherLayer({
        map: self.map,
        temperatureUnits: google.maps.weather.TemperatureUnit.FAHRENHEIT,
        windSpeedUnits: google.maps.weather.WindSpeedUnit.MILES_PER_HOUR
      });
    }

    if (self.options.useCloudLayer) {
      new google.maps.weather.CloudLayer({
        map: self.map
      });
      // cloud_layer.setMap(self.map);
    }
  };

  SamplesMap.prototype.createMarker = function (entity, markerSpec, keyFn) {
    var self = this;

    if (markerSpec) {
      markerSpec.infoWindow = markerSpec.infoWindow || {};
    }

    markerSpec.titleEngine = markerSpec.titleEngine || self.templateCompiler(markerSpec.title || "");
    markerSpec.infoWindow.titleEngine = markerSpec.infoWindow.titleEngine || self.templateCompiler(markerSpec.infoWindow.title || "");
    markerSpec.infoWindow.contentEngine = markerSpec.infoWindow.contentEngine || self.templateCompiler(markerSpec.infoWindow.content || "");

    var markerOptions = {
      position: new google.maps.LatLng(entity.latitude, entity.longitude)
      , icon: markerSpec.icon
      , map: self.map
      , title: markerSpec.titleEngine(entity)
    };
    var marker = new google.maps.Marker(markerOptions);
    if (self.oms) {
      self.oms.addMarker(marker);
    }

    var infoWindowOptions = {
      title: markerSpec.infoWindow.titleEngine(entity)
      , content: markerSpec.infoWindow.contentEngine(entity)
    };

    self.addListener(marker, "click", function (marker_param, evt) {
      if (marker_param === marker) {
        if (!self.infoWindow) {
          self.infoWindow = new google.maps.InfoWindow();
        }
        self.infoWindow.close();

        if (infoWindowOptions.title || infoWindowOptions.content) {
          self.infoWindow.setOptions(infoWindowOptions);
          self.infoWindow.open(self.map, marker_param);
        }
      }
    });
    var finalKeyFn = keyFn;
    if (typeof (finalKeyFn) === "undefined") {
      finalKeyFn = function (entity) {
        self._keyAutoMemberId = self._keyAutoMemberId || 0;
        return ["auto", self._keyPrefix, (entity.id || self._keyAutoMemberId++)].join("-");
      };
    }

    if (!self.markers) self.markers = {};
    let key = finalKeyFn.call(undefined, entity);
    self.markers[key] = marker;
    return marker;
  };

  SamplesMap.prototype.addListener = function (marker, eventName, callback) {
    const self = this;
    const fixedEventName = (this.oms && eventName === "click") ? "spider_click" : eventName;
    google.maps.event.addListener(marker, fixedEventName, function (evt) {
      callback(marker, evt);
    });
  };

  SamplesMap.prototype.createMarkers = function (entities, markerSpec, keyFn) {
    var self = this;

    if (!$.isFunction(keyFn)) {
      //Need to create one
      if (_.isString(keyFn)) {
        //use prefix and assume entity has an "id" property
        var prefix = keyFn;
        keyFn = function (entity) {
          return [prefix, entity.id].join("-");
        };
      } else {
        // increment the prefix; this is likely a new collection
        self._keyPrefix++;
      }
    }

    $.each(entities, function () {
      self.createMarker(this, markerSpec, keyFn);
    });

    if (entities.length > 0) { // don't adjust previous center/zoom if there were no entities
      this.fitAndConstrainZoom(18);
    }
  };

  SamplesMap.prototype.locateMarker = function (key) {
    const self = this;
    const marker = self.markers[key]
    const map = self.map;
    if (marker) {
      // map.setCenter(map.getCenter(), 5);
      // map.panTo(marker.position);
      google.maps.event.trigger(marker, 'click');
    }
  };

  SamplesMap.prototype.createKml = function (kmlUrl, options) {
    var self = this;
    self.kmls = self.kmls || [];
    var settings = $.extend({}, { map: self.map, preserveViewport: true }, options);
    self.kmls.push({ url: kmlUrl, layer: new google.maps.KmlLayer(kmlUrl, settings) });
  };

  SamplesMap.prototype.createKmls = function (kmlUrls, options) {
    var self = this;
    $.each(kmlUrls, function (i, kmlUrl) {
      self.createKml(kmlUrl, options);
    });
  };

  SamplesMap.prototype.fitAndConstrainZoom = function (maxZoom) {
    var self = this;
    var bounds = new google.maps.LatLngBounds();
    $.each(self.markers || [], function () {
      bounds.extend(this.position);
    });

    //Set zoom after final bounds change
    google.maps.event.addListenerOnce(self.map, "bounds_changed", function (evt) {
      if (self.map.getZoom() > maxZoom) {
        self.map.setZoom(maxZoom);
      }
    });

    self.map.fitBounds(bounds);
  };

  SamplesMap.prototype.highlightRelativeToCenter = function (latLng, maxZoom) {
    var self = this;
    var previousCenter = self.map.center;
    google.maps.event.addListenerOnce(self.map, "bounds_changed", function () {
      self.map.setCenter(previousCenter);
      self.map.setZoom(18); //zoom too far before expanding
      var newBounds = self.map.getBounds().extend(latLng);
      self.map.fitBounds(newBounds);
      if (self.map.getZoom() > maxZoom) {
        self.map.setZoom(maxZoom);
      }
    });
  };

  SamplesMap.prototype.initializeDraggableBoundsSelector = function (options) {
    var self = this;
    self.boundsRectangle = new google.maps.Rectangle({
      map: self.map
      , draggable: true
      , editable: true
    });
    self.draggableBoundsSelectorOptions = options;

    var updateBoundsRectangle = function (sw, ne) {
      self.boundsRectangle.setBounds(new google.maps.LatLngBounds(sw.position, ne.position));
    };

    google.maps.event.addListener(self.boundsRectangle, "bounds_changed", function (evt) {
      var bounds = self.boundsRectangle.getBounds();
      if (bounds) {
        var sw = bounds.getSouthWest();
        var ne = bounds.getNorthEast();
        $(options.sw.targetLat).val(sw.lat());
        $(options.sw.targetLng).val(sw.lng());
        $(options.ne.targetLat).val(ne.lat());
        $(options.ne.targetLng).val(ne.lng());
      }
    });

    $([
      $(options.sw.targetLat).get(0)
      , $(options.sw.targetLng).get(0)
      , $(options.ne.targetLat).get(0)
      , $(options.ne.targetLng).get(0)
    ]).on("change", function () {
      var sw_lat = $(options.sw.targetLat).val();
      var sw_lng = $(options.sw.targetLng).val();
      var ne_lat = $(options.ne.targetLat).val();
      var ne_lng = $(options.ne.targetLng).val();
      var bounds = null;
      if (_.all([sw_lat, sw_lng, ne_lat, ne_lng])) {
        bounds = new google.maps.LatLngBounds(new google.maps.LatLng(sw_lat, sw_lng), new google.maps.LatLng(ne_lat, ne_lng));
      }

      self.boundsRectangle.setBounds(bounds);
    });

    if (options.sw.position && options.ne.position) {
      updateBoundsRectangle(options.sw, options.ne);
    }

    google.maps.event.addListener(self.map, "click", function (evt) {
      if (!self.boundsRectangle.getBounds()) {
        var item = { position: evt.latLng };
        updateBoundsRectangle(item, item);
      }
    });

    return self.boundsRectangle;
  };

  SamplesMap.prototype.clearDraggableBoundsSelector = function () {
    var self = this;
    var options = self.draggableBoundsSelectorOptions;
    self.boundsRectangle.setBounds(null);
    $(options.sw.targetLat).val("");
    $(options.sw.targetLng).val("");
    $(options.ne.targetLat).val("");
    $(options.ne.targetLng).val("");
  };

  SamplesMap.showSieraLightbox = function (id) {
    var siera = _.find(Samples._sieras, { id: id });
    if (siera && _.some(siera.photos)) {
      var photo_data = _.map(siera.photos, function (p) {
        return { href: p, type: "image" };
      });
      $.fancybox.open(photo_data);
    }
  };

  SamplesMap.showRtrLightbox = function (id) {
    var rtr = _.find(Samples._real_time_readings, { id: id });
    if (rtr && _.some(rtr.photos)) {
      var photo_data = _.map(rtr.photos, function (p) {
        return { href: p, type: "image" };
      });
      $.fancybox.open(photo_data);
    }
  };

  SamplesMap.latLng = function (entity) {
    return new google.maps.LatLng(entity.latitude, entity.longitude);
  };

  window.SamplesMap = SamplesMap;
})(jQuery);
