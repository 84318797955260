import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Brush, ResponsiveContainer, Legend,
} from "recharts";

import { useQuery } from "@apollo/client";
import { getRealTimeReadingsQuery } from "../../graphql/queries/getRealTimeReadings";
import Spinner from "../Spinner";
import { apolloClient } from "../../graphql/apollo";
import * as moment from "moment";
// import ReactTable from "react-table";
import {
  useTable,
  useSortBy
} from "react-table";
import ProjectsMap from "../ProjectsMap";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  )

  return (
    <>
      <table className="table table-condensed table-striped-5" {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(
            (row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    )
                  })}
                </tr>
              )
            }
          )}
        </tbody>
      </table>
    </>
  )
}

const AnalyteChart = ({ filter, projectId }) => {
  const [activeRealTimeRecord, setActiveRealTimeRecord] = useState(null);
  const { loading, error, data } = useQuery(getRealTimeReadingsQuery, {
    client: apolloClient,
    variables: {
      projectId: projectId,
      day: filter.day,
      startingAt: filter.startingAt,
      endingAt: filter.endingAt,
      analyte: filter.analyte,
      instrument: filter.instrument,
      units: filter.units,
      conc: filter.conc,
      detects: filter.detects,
      detectionFlag: filter.detectionFlag,
      locationCategory: filter.locationCategory,
      initials: filter.initials,
      locationId: filter.locationId,
      planId: filter.planId,
      atrId: filter.atrId,
      region: filter.region
    }
  });

  const parseForChart = readings => {
    return readings.map(reading => {
      let chartReading;
      const r = { ...reading };

      if (r.concentration !== "" && r.concentration !== null) {
        chartReading = r.concentration;
      } else {
        chartReading = r.detectionLimit;
      }

      r.chartDate = moment.utc(r.readingDate).valueOf();

      if (r.weatherWindGustMph) {
        r.weatherWindGustMph = parseFloat(r.weatherWindGustMph);
      }

      return { ...r, chartReading };
    });
  };

  const WeatherTooltip = ({ payload, label, active }) => {
    if (active && payload && payload[0] && payload[0].payload) {
      const rtr = payload[0].payload;

      return (
        <div className="custom-tooltip">
          <i className="fas fa-arrow-down" style={{ transform: `rotate(${rtr.weatherWindDegrees}deg)`, float: "right" }} />
          Wind Speed: {rtr.weatherWindMph} mph&nbsp;<br />
          Gust Speed: {rtr.weatherWindGustMph} mph<br />

        </div>
      );
    }

    return null;
  };

  const ConcentrationTooltip = ({ payload, label, active }) => {
    if (active && payload && payload[0] && payload[0].payload) {
      const rtr = payload[0].payload;

      return (
        <div className="custom-tooltip">
          {rtr.chartReading} {rtr.units} @ <strong>{moment.utc(label).format("MM/DD/YY HH:mm")}</strong>
        </div>
      );
    }

    return null;
  };

  const setCurrentReading = e => {
    if (e === null) {
      return;
    }
    if (!e.activePayload || !e.activePayload[0].payload) {
      if (activeRealTimeRecord !== null) {
        setActiveRealTimeRecord(null);
      }

      return;
    }

    if (activeRealTimeRecord !== e.activePayload[0].payload.id) {
      setActiveRealTimeRecord(e.activePayload[0].payload.id);
    }
  };

  const getTickFormatter = realTimeReadings => {
    const dateRange = realTimeReadings.reduce((memo, r) => {
      return {
        min: Math.min(memo.min, r.chartDate),
        max: Math.max(memo.max, r.chartDate),
      };
    }, { min: Infinity, max: -Infinity });
    const hours = moment(dateRange.max).diff(moment(dateRange.min), "hours");
    if (hours >= 48) {
      return (unixTime) => moment.utc(unixTime).format("MM/DD HH:mm");
    } else {
      return (unixTime) => moment.utc(unixTime).format("HH:mm");
    }
  };

  const linkFormatter = reading => {
    return (
      <a href={`/real_time_readings/${reading.id}`}>
        {moment.utc(reading.readingDate).format("M/D @ HH:mm")}
      </a>
    );
  };

  const readingFormatter = reading => {
    let formatted;

    if (reading.concentration) {
      formatted = reading.concentration;
    } else if (reading.detectionFlag === ">") {
      return `${reading.detectionFlag} Upper Limit`;
    } else {
      formatted = `${reading.detectionFlag}${reading.detectionLimit}`;
    }

    return `${formatted} ${reading.units}`;
  };

  const renderTable = realTimeReadings => {
    return (
      <Table
        data={realTimeReadings}
        columns={[
          {
            Header: "Time",
            id: "timeLink",
            accessor: r => linkFormatter(r)
          },
          {
            Header: "Category",
            accessor: "locationCategory",
          },
          {
            Header: "Analyte",
            accessor: "analyte",
          },
          {
            Header: "Instrument",
            accessor: "instrument",
          },
          {
            Header: "Reading",
            id: "reading",
            accessor: r => readingFormatter(r),
            sortType: (rowA, rowB, columnId, desc) => {
              if (rowA.original.detectionFlag === ">") { return 1; }
              if (rowB.original.detectionFlag === ">") { return -1; }
              if (rowB.original.detectionFlag === "<") { return 1; }
              if (rowA.original.detectionFlag === "<") { return -1; }
              if (!rowA.original.concentration) { return -1; }
              if (!rowB.original.concentration) { return 1; }
              return rowA.original.concentration < rowB.original.concentration ? -1 : 1
            },
          }
        ]}
        defaultPageSize={20}
        showPaginationTop={false}
        showPaginationBottom={true}
        minRows={0}
        showPageSizeOptions={false}
        showPageJump={false}
        showPagination={realTimeReadings.length > 20}
        className="-striped -highlight primary-pagination"
      />
    );
  };

  if (!filter || !filter.analyte) {
    return (<>
      <h4 className="text-center">Select a value on the left for details</h4>
    </>);
  }

  if (loading) {
    return <Spinner />;
  }

  if (data && data.projects && data.projects.length > 0) {
    const realTimeReadings = parseForChart(data.projects[0].realTimeReadings);
    const tickFormatter = getTickFormatter(realTimeReadings);
    return (
      <>
        <ProjectsMap project={data.projects[0]} activeRealTimeRecord={activeRealTimeRecord} />
        <ResponsiveContainer width="100%" height={200} className="chart-container">
          <LineChart
            onClick={e => setCurrentReading(e)}
            data={realTimeReadings}
            syncId="anyId"
          >
            <defs>
              <linearGradient id="colorUv" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stopColor="magenta" />
                <stop offset="100%" stopColor="black" />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis axisLine={false} dataKey="chartDate" scale="time" type="number"
              domain={["dataMin", "dataMax"]}
              tickFormatter={tickFormatter} />
            <YAxis axisLine={false} mirror={true} domain={["dataMin", "dataMax"]} />
            <Tooltip content={ConcentrationTooltip} />
            <Brush />
            <Line type="monotone" dataKey="chartReading" stroke="url(#colorUv)" dot={false} />
          </LineChart>
        </ResponsiveContainer>
        <ResponsiveContainer width="100%" height={200}>
          <LineChart
            data={realTimeReadings}
            onClick={e => setCurrentReading(e)}
            syncId="anyId"
          >
            <defs>
              <linearGradient id="colorWind" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stopColor="chartreuse" />
                <stop offset="100%" stopColor="darkgreen" />
              </linearGradient>
              <linearGradient id="colorGust" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stopColor="lightblue" />
                <stop offset="100%" stopColor="darkblue" />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis axisLine={false} dataKey="chartDate" scale="time" type="number"
              domain={["dataMin", "dataMax"]}
              tickFormatter={tickFormatter} />
            <YAxis axisLine={false} mirror={true} domain={["dataMin", "dataMax"]} />
            <Tooltip content={WeatherTooltip} />
            <Legend />
            <Line type="monotone" dataKey="weatherWindMph" stroke="url(#colorWind)" dot={false}
              name="Wind Speed (mph)" />
            <Line type="monotone" dataKey="weatherWindGustMph" stroke="url(#colorGust)" dot={false}
              name="Gust Speed (mph)" />
          </LineChart>
        </ResponsiveContainer>
        {renderTable(realTimeReadings)}
      </>
    );
  }
  return null;
};

export default AnalyteChart;