import React, { Component } from "react";

class Spinner extends Component {
  render() {
    return (
      <div className="spinner-container">
        <span className="spinner" />
      </div>
    );
  }
}

export default Spinner;
