/**
 *
 * Created by jefffendley on 6/9/16.
 */

$(function(){
  $('button[data-ajax-toggle]').on('click', function() {
    // Creates a button that toggles between values on a field by issuing a rails update request
    var $this = $(this);
    var resourceId = $this.data('update');
    var resourceClassName = $this.data('resourceClassName');
    var toggleField = $this.data('toggleField');
    var url = window.location.protocol + '//' + window.location.host + _.template($this.data('ajaxToggle'))({resourceId: resourceId});
    var ajaxData = {"_method": "put"}; // NOTE: Should be PATCH on rails 4, I think
    var success_callback, error_callback;
    var currentToggleValue = $this.data('toggleFieldCurrent');
    var trueValue = $this.data('trueValue');
    if (trueValue == '') { trueValue = 1; }
    var falseValue = $this.data('falseValue');
    if (falseValue == '') { falseValue = 0; }
    var toggleTextTrue = $this.data('toggleTextTrue');
    var toggleTextFalse = $this.data('toggleTextFalse');
    var trueIsNegative = $this.data('trueIsNegative');
    if (toggleField) {
      var newToggleValue = currentToggleValue == trueValue ? falseValue : trueValue;

      ajaxData[resourceClassName + '[' + toggleField + ']'] = newToggleValue;
      $this.removeClass('btn-danger btn-warning');
      $this.addClass('btn-primary');

      error_callback = function (res) {
        $this.removeClass('btn-primary');
        $this.addClass('btn-danger');
        console.log(res);
      };
      success_callback = function (res) {
        $this.data('toggleFieldCurrent', newToggleValue);
        $this.removeClass('btn-primary');
        var newToggleValueIsPositive = (newToggleValue == (trueIsNegative ? falseValue : trueValue));
        $this.toggleClass('btn-warning', newToggleValueIsPositive);
        if (toggleTextTrue != '' && toggleTextFalse != '') {
          $this.text(newToggleValue == falseValue ? toggleTextFalse : toggleTextTrue);
        }
      };
    } else {
      console.log('No toggle field set');
    }

    var ajaxOptions = {
      url: url,
      method: 'POST',
      data: ajaxData,
      error: error_callback,
      success: success_callback
    };
    $.ajax(ajaxOptions);
  });
});
