import $ from "jquery";
import EasyMDE from "easymde";
import marked from "marked";

$(function () {
  $(".chosen-select, .selectize").selectize({
    // allowEmptyOption: false,
  });

  $(".fancybox").fancybox();

  $("[data-add-anchor]").each(function () {
    const anchor = $(this).data("addAnchor");
    $(this).find("a").each(function () {
      const href = $(this).attr("href").replace(/(#.*)?$/, "#" + anchor);
      $(this).attr("href", href);
    });
  });

  $("[data-complete=\"map\"]").on("click", function (e) {
    let $this = $(this);
    let lat = $($this.data("targetLat") || "[name*=\"latitude\"],[name=\"lat\"]").first();
    let lng = $($this.data("targetLng") || "[name*=\"longitude\"],[name=\"lng\"]").first();
    let map = window[$this.data("mapVar") || "map"];
    let layersToCapture = ($this.data("completeMapLayersToCapture") || []).concat(map);

    var removeListeners = function ($this) {
      _.forEach($this.data("listeners"), function (listener) {
        google.maps.event.removeListener(listener);
      });
      $this.removeData("listeners");
    };

    var setSelecting = function (selecting) {
      $this.data("selecting", selecting);

      _.forEach(layersToCapture, function (layer) {
        if (layer !== map) {
          layer.setOptions({clickable: !selecting});
        }
      });

      if (selecting) {
        $this.tooltip({trigger: "manual", title: "Click on map to select new location"});
      }
      $this.tooltip(selecting ? "show" : "destroy");

      if (!selecting)
        removeListeners($this);

      $(map.getDiv()).css("box-shadow", selecting ? "0 0 8px 0 red" : "");
    };

    var getNewLocationMarker = function () {
      var marker = $this.data("newLocation");
      if (marker) {
        return marker;
      }

      marker = new google.maps.Marker({map: map, draggable: true});
      google.maps.event.addListener(marker, "dragend", function (evt) {
        updateMarkerPosition(marker, evt.latLng);
        updateInputs(evt.latLng);
      });
      $this.data("newLocation", marker);
      return marker;
    };

    var updateMarkerPosition = function (marker, latLng) {
      marker.setPosition(latLng);
      marker.setTitle("Updated Location: " + latLng.lat() + ", " + latLng.lng());
    };

    var updateInputs = function (latLng) {
      lat.val(latLng.lat());
      lng.val(latLng.lng());
      lat.add(lng).css("box-shadow", "0 0 8px 0 red");
    };

    //Set reverse flag creation and update if not already done
    if (!$this.data("reverseBindCreated")) {
      lat.add(lng).on("change", function () {
        var marker = getNewLocationMarker();
        var latLng = new google.maps.LatLng(lat.val(), lng.val());
        updateMarkerPosition(marker, latLng);
        updateInputs(latLng);
      });
      $this.data("reverseBindCreated", true);
    }

    //Handle button un-click; map var must be set
    if ($this.data("selecting")) {
      setSelecting(false);
      return;
    }

    //Here we know it's an initial button click
    setSelecting(true);
    $this.data("listeners", _.map(layersToCapture, function (layer) {
      return google.maps.event.addListenerOnce(layer, "click", function (evt) {
        var marker = getNewLocationMarker();
        updateMarkerPosition(marker, evt.latLng);
        updateInputs(evt.latLng);
        evt.stop();


        setSelecting(false);

        $this.trigger("complete", {mouseEvent: evt});

      });
    }));
    return false; //button click
  });

  $("[data-complete=\"geolocation\"]").on("click", function (e) {
    var $this = $(this);
    var lat = $($this.data("targetLat") || "[name*=\"latitude\"],[name=\"lat\"]").first();
    var lng = $($this.data("targetLng") || "[name*=\"longitude\"],[name=\"lng\"]").first();
    setGpsFieldsWithGeolocation(lat, lng);
  });

  $(".navbar-nav [data-complete=\"typeahead\"]").on("click", function (evt) {
    evt.stopPropagation(); //keep dropdown from closing on click
  });

  $(".navbar-nav").on("shown.bs.dropdown", function (evt) {
    $(evt.target).find(".tt-query").focus();
  });

  $("body").tooltip({selector: "[data-toggle=tooltip]"});

  // wrap th's for .table-header-rotated
  $(".table-header-rotated th:not(:has(div>span)), .table-header-rotated-45 th:not(:has(div>span))").each(function () {
    $(this).wrapInner("<div><span></span></div>");
  });

  // select-all checkbox
  $("input[name=all-rows-selector]").on("change", function (e) {
    let table = $(this).closest("table");
    let selectors = table.find("input.row-selector");
    selectors.prop("checked", $(this).prop("checked"));
    selectors.first().trigger("change");
    e.stopImmediatePropagation();
  });

  $('textarea[data-type="markdown"]').each(function() {
    new EasyMDE({element: this});
  })

  $('.marked').each(function(){
    this.innerHTML = marked(this.innerHTML);
  })
});

function setGpsFieldsWithGeolocation(latField, lngField) {
  navigator.geolocation.getCurrentPosition(function (position) {
    latField.val(position.coords.latitude).trigger("change");
    lngField.val(position.coords.longitude).trigger("change");
  });
}

window.Samples = {};
